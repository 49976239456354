import ApiService from '../Api';

class UserWarehouseServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/user-warehouse`, { params });
  }

  show(archon, userWarehouse) {
    return this.api.get(`${this.resource}/${archon}/user-warehouse/${userWarehouse}`);
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/user-warehouse`, body);
  }

  update(archon, userWarehouse, body) {
    return this.api.put(`${this.resource}/${archon}/user-warehouse/${userWarehouse}`, body);
  }

  destroy(archon, userWarehouse) {
    return this.api.delete(`${this.resource}/${archon}/user-warehouse/${userWarehouse}`);
  }
}

export default new UserWarehouseServices();