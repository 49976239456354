export const TRANSLATORS_TYPE = {
  SELL: {
    color: '#00897B',
    value: 'Venta',
  },
  COST: {
    color: '#D4E157',
    value: 'Costo',
  },
  BUY: {
    color: '#FF8A65',
    value: 'Compra',
  },
};

export const STATUS_COLOR = {
  SHIPPED: 'success',
  PROCESSING: 'warning',
  CANCEL: 'grey darken-2',
  FAST_SHIPPING: 'primary',
  SALE: 'primary',
  PENDING: 'grey lighten-1',
  ACTIVE: 'success',
  INACTIVE: 'error',
  FAST_BUY: 'primary',
  UNDERPAYMENT: 'orange',
};

export const STATUS_TRANSLATOR = {
  SHIPPED: 'FINALIZADO',
  PROCESSING: 'EN PROCESO',
  CANCEL: 'CANCELADO',
  FAST_SHIPPING: 'ENTREGA RAPIDA',
  SALE: 'VENDIDO',
  PENDING: 'PENDIENTE',
  FAST_BUY: 'RECIBIDO',
  UNDERPAYMENT: 'PAGO PENDIENTE',
};

export const WAREHOUSE_ICON = {
  WAREHOUSE: 'mdi-warehouse',
  STORE: 'mdi-store',
  MOVIL: 'mdi-truck',
};

export const ORDER_TYPE = {
  DISPATCH: 'Transferencia',
  RESTORE: 'Devolución',
  SALE: 'Venta',
  PURCHASE: 'Pedido',
  BUY: 'Compra',
  RETURN: 'Devolucion',
  SALE_PAYMENT: 'Crédito',
};