import ApiService from '../Api';

class WarehouseServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/warehouses`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/warehouses`, body);
  }

  show(archon, warehouse, params) {
    return this.api.get(`${this.resource}/${archon}/warehouses/${warehouse}`, {params})
  }

  update(archon, warehouse, body) {
    return this.api.put(`${this.resource}/${archon}/warehouses/${warehouse}`, body);
  }
}

export default new WarehouseServices();