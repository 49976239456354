import ApiService from '../Api';

class UserServices extends ApiService {
  constructor() {
    super('/users');
  }
  index(params = {}) {
    return this.api.get(`${this.resource}`, { params });
  }
  // index2(archon, params = {}) {
  //   return this.api.get(`archons/${archon}/users-archon`, { params });
  // }
  store(body) {
    return this.api.post(`${this.resource}`, body);
  }

  update(body, user) {
    return this.api.put(`${this.resource}/${user}`, body);
  }
}

export default new UserServices();