<template>
  <div>
    <v-row>
      <v-col cols="12" md="5" sm="12">
        <v-card flat>
          <v-card-text>
            <v-form ref="form">
              <v-select
                v-model="currentWarehouse"
                label="Almacenes"
                :items="warehouses"
                dense
                outlined
                item-text="name"
                return-object
                :loading="loadingW"
                :rules="[isObj]"
              />
              <v-select
                v-model="currentUser"
                label="Usuarios"
                :items="users"
                item-text="name"
                dense
                outlined
                return-object
                :loading="loadingU"
                :rules="[isObj]"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              small
              @click="storeUserWarehouse"
              text
              :loading="loadingStore"
              :disabled="loadingStore"
            >
              Asignar
              <v-icon right>mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" sm="12">
        <v-card flat min-height="300">
          <Loader absolute :loading="loadingUW" />
          <div class="d-flex justify-end">
            <v-btn
              @click="getUserWarehouses"
              color="warning"
              small
              text
            >
              <v-icon left>mdi-refresh</v-icon>
              Refrescar
            </v-btn>
          </div>
          <v-data-table
            :headers="headers"
            :items="userWarehouses"
            class="elevation-0"
            disable-pagination
            hide-default-footer
            fixed-header
          >
            <template v-slot:item.user="{ item }">
              <div>
                <div><b>Nombre</b>: {{ item.user.name }}</div>
                <div><b>Email</b>: {{ item.user.email }}</div>
              </div>
            </template>
            <template v-slot:item.warehouse="{ item }">
              <div>
                <div>
                  <v-icon left small color="primary">{{ warehouseIcon[item.warehouse.type] }}</v-icon>
                  <b>Almacen</b>: {{ item.warehouse.name }}
                </div>
                <div><b>Tipo</b>: {{ typesTranslate[item.warehouse.type] }}</div>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-progress-circular
                v-if="userWarehouseId === item.id"
                indeterminate
                color="primary"
              />
              <v-btn
                v-else
                icon
                small
                @click="showMyDialog(item)"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <!-- :height="heightProducts" -->
        </v-card>
      </v-col>
    </v-row>
    <MyDialog
      ref="myDialog"
      @onYes="removeUserWarehouse"
    />
  </div>
</template>

<script>
import WarehouseServices from '@/store/services/warehouse/WarehouseServices';
import UserWarehouseServices from '@/store/services/warehouse/UserWarehouseServices';
import UserServices from '@/store/services/goliath/UserServices'
import { WAREHOUSE_ICON } from '@/constants';
import { isObject } from '@/constants/rules';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      warehouses: [],
      users: [],
      userWarehouses: [],

      userWarehouseId: -1,

      loadingUW: false,
      loadingW: false,
      loadingU: false,

      typesTranslate: {
        WAREHOUSE: 'ALMACEN',
        STORE: 'TIENDA',
        MOVIL: 'MOVIL',
      },
      warehouseIcon: WAREHOUSE_ICON,

      currentUser: {},
      currentWarehouse: {},
      currentUserWarehouse: {},

      loadingStore: false,

      // rules
      isObj: value => isObject(value) || 'Es campo requerido',
    };
  },
  created() {
    this.getUserWarehouses();
    this.getWarehouses();
    this.getUsers();
  },
  methods: {
    showMyDialog(item) {
      this.currentUserWarehouse = item;
      const myDialog = this.$refs['myDialog'];
      myDialog.configuration = {
        title: 'Quitar Asignación',
        message: '¿Usted va a quitar la asignación del usuario con el warehouse?',
        typeDialog: 'warning',
      };
      myDialog.dialog = true;
    },
    async removeUserWarehouse() {
      try {
        this.userWarehouseId = this.currentUserWarehouse.id;
        await UserWarehouseServices.destroy(this.user.archon.id, this.currentUserWarehouse.id);
        const index = this.userWarehouses.findIndex(uw => uw.id === this.currentUserWarehouse.id);
        this.userWarehouses.splice(index, 1);
        this.userWarehouseId = -1;
      } catch (error) {
        this.userWarehouseId = -1;
      }
    },
    async getWarehouses() {
      try {
        this.loadingW = true;
        const response = await WarehouseServices.index(this.user.archon.id);
        this.warehouses = response.data.data;
        this.loadingW = false;
      } catch (error) {
        this.loadingW = false;
      }
    },
    async getUsers() {
      try {
        this.loadingU = true;
        const params = {
          include: 'archons',
          search_archon: this.user.archon.id,
        };
        const response = await UserServices.index(params);
        this.users = response.data.data;
        this.loadingU = false;
      } catch (error) {
        this.loadingU = false;
      }
    },
    async getUserWarehouses() {
      try {
        this.loadingUW = true;
        const params = {
          include: 'user,warehouse',
        };
        const response = await UserWarehouseServices.index(this.user.archon.id, params);
        this.userWarehouses = response.data.data;
        this.loadingUW = false;
      } catch (error) {
        this.loadingUW = false;
      }
    },
    async storeUserWarehouse() {
      if (this.$refs.form.validate()) {
        if (this.validateUserWarehouse()) {
          try {
            this.loadingStore = true;
            const body = {
              warehouse_id: this.currentWarehouse.id,
              user_id: this.currentUser.id,
            };
            const response = await UserWarehouseServices.store(this.user.archon.id, body);
            const newUserWarehouse = response.data;
            newUserWarehouse['user'] = this.currentUser;
            newUserWarehouse['warehouse'] = this.currentWarehouse;
            this.userWarehouses.unshift(newUserWarehouse);
            this.loadingStore = false;
          } catch (error) {
            this.loadingStore = false;
            console.log(error);            
          }
        } else this.$notify({
          group: 'petition',
          title: 'Horus',
          type: 'warning',
          text: `El usuario ${this.currentUser.name} solo puede tener asignado un almacen`,
        });
      }
    },
    validateUserWarehouse() {
      return this.userWarehouses.filter(uw => uw.user_id === this.currentUser.id).length === 0;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: 'Usuario',
          value: 'user',
          sortable: false,
        },
        {
          text: 'Almacen',
          value: 'warehouse',
          sortable: false,
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
      ];
    },
  },
};
</script>